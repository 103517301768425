<template>
  <BaseContainer>
    <PAYGUpgradeComplete />
  </BaseContainer>
</template>

<script>
import PAYGUpgradeComplete from '@components/Plans/PAYGUpgradeComplete'

export default {
  components: {
    PAYGUpgradeComplete
  },

  page: {
    title: 'Upgrade Complete'
  }
}
</script>
