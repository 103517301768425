<template>
  <div>
    <div v-show="state === states.READY">
      <BasePageHeader v-if="state === states.READY">
        <div>
          <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
          <BasePageHeading>Start Subscription</BasePageHeading>
        </div>
      </BasePageHeader>
      <BaseWrapper>
        <div class="text-center">
          <h1 class="font-bold text-4xl my-6">
            Thank you
          </h1>
          <p class="my-2">
            We’ve taken your payment for your upgrade and you’ve started your new subscription
          </p>
          <div>
            <BaseButton
              variant="green"
              class="my-12"
              :to="{ name: 'client-assessments-list' }"
            >
              Return to your Assessments
            </BaseButton>
          </div>
        </div>
      </BaseWrapper>
    </div>
    <div v-if="state === states.LOADING">
      <BasePageHeader>
        <div>
          <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
          <BasePageHeading>Start Subscription</BasePageHeading>
        </div>
      </BasePageHeader>
      <Loader />
      <p
        v-if="loadingMessage"
        class="text-center mt-4"
      >
        {{ loadingMessage }}
      </p>
    </div>
    <div v-else-if="state === states.ERROR">
      <BaseErrorBlock />
    </div>
  </div>
</template>

<script>
import Loader from '@components/Loader'

import states from '@api/states'
import { mapGetters } from 'vuex'

export default {
  components: {
    Loader
  },

  data() {
    return {
      states,

      error: null,
      loadingMessage: null,

      success: false
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      // Normally with breadcrumbs() we only use this when the page has
      // fully loaded, but on the checkout it makes a nice consistency if
      // the breadcrumb is always there, so we only require that the
      // organisationName is there for use
      if (!this.organisationName) {
        return []
      }

      return [
        {
          name: this.organisationName
        },
        {
          name: 'Plans',
          to: 'settings-payg-plan'
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (!this.success) {
        return states.LOADING
      }

      return states.READY
    }
  },

  mounted() {
    this.success = true
  }
}
</script>
